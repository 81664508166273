.Home {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  table, th, td{
    border:1px solid black;
    width:100%;
    border-collapse:collapse;
    
  }
  td{
    width: 1%;
    font-size:12px;
  }
  .maxWidth{
    max-width: 600px;
  }
  thead{
    font-weight: bold;
    background-color: ivory;
  }
  